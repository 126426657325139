/**
 * Usabilla is used to trigger surveys on the site.
 */

import { getLocalStorageAnonymousId, getLocalStorageUserId } from "./analytics";

interface UsabillaDataOptions {
  custom: {
    segment_anonid: string;
    segment_uid?: string;
  };
}

export interface UsabillaLive {
  (command: "data", options: UsabillaDataOptions): void;
  (command: "trigger", name: string): void;
}

// Usabilla script is loaded in the index.html file.
declare global {
  interface Window {
    usabilla_live: UsabillaLive;
  }
}

export const usabillaTrigger = (name: string) => {
  if (!window?.usabilla_live) return;

  // Usabilla don't have integration with Segment, so we need to pass the user id and anonymous id manually.
  const anonymousId = getLocalStorageUserId();
  const userId = getLocalStorageAnonymousId();

  // We need at least the anonymous id.
  if (!anonymousId) return;

  // First, pass the user id and anonymous id to Usabilla.
  window.usabilla_live("data", {
    custom: userId
      ? {
          segment_anonid: anonymousId,
          segment_uid: userId,
        }
      : {
          segment_anonid: anonymousId,
        },
  });

  // Then trigger the survey.
  window.usabilla_live("trigger", name);
};
