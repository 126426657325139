import * as Sentry from "@sentry/react";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";

import { ErrorPage } from "apps-common/components/ErrorPage";
import {
  CreditCardCallbackPage,
  HSACallbackPage,
  PaypalCallbackPage,
} from "apps-common/components/PaymentCallbackPage";
import { useAuth } from "apps-common/context/auth";
import { AccessUserTypes, PaymentMethodType } from "apps-common/types";
import { track } from "apps-common/utils/analytics";
import { ScrollToTop } from "apps-common/utils/ScrollToTop";
import { Footer } from "ui";
import { BasePage } from "ui/components/BasePage";
import { Container } from "ui/styles/containers";

import { AccountManagePage } from "./pages/AccountManagePage";
import { AddressPage } from "./pages/AddressPage";
import {
  RequestPasswordResetPage,
  ResetPasswordPage,
} from "./pages/ForgotPasswordPage";
import { LoginPage } from "./pages/LoginPage";
import { MembershipExperiencePage } from "./pages/MembershipExperiencePage";
import { MembershipHubPage } from "./pages/MembershipHubPage";
import { MembershipPlanPage } from "./pages/MembershipPlanPage";
import { PaymentDetailsPageSelectAddressUsage } from "./pages/PaymentDetailsPageSelectAddressUsage";
import { PaymentMethodUpdatePage } from "./pages/PaymentMethodUpdatePage";
import { RenewSelectPlanPage } from "./pages/RenewSelectPlanPage";
import { ReviewPaymentMethodForRenew } from "./pages/ReviewPaymentMethodForRenew";
import { UpdatePaymentMethodSuccess } from "./pages/UpdatePaymentMethodSuccess";
import { UpdatePlanPage } from "./pages/UpdatePlanPage";
import { VerifyEmailPage } from "./pages/VerifyEmailPage";
import { ViewBillingHistoryPage } from "./pages/ViewBillingHistoryPage";
import { routes } from "./routes";
import { useStore } from "./store";
import {
  onCallbackError,
  onCallbackSuccess,
} from "./utils/paymentMethodCallback";
import { WithAuthAndAccessCheck } from "./utils/WithAuthAccessCheck";

// https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/
const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const Root = () => {
  const { loggedIn } = useAuth();
  return (
    <>
      <ScrollToTop />
      <Container>
        <BasePage>
          <Outlet />
          <Footer
            onLinkClick={({ name }) => {
              track({
                event: "Link Clicked",
                payload: { cta: name, location: "footer" },
              });
            }}
            showLogout={loggedIn}
          />
        </BasePage>
      </Container>
    </>
  );
};

const router = sentryCreateBrowserRouter([
  {
    path: routes.index,
    element: <Root />,
    children: [
      {
        errorElement: <ErrorPage appType="hub" />,
        children: [
          {
            index: true,
            element: (
              <WithAuthAndAccessCheck
                userAccessRights={{
                  excludedUsers: [
                    AccessUserTypes.UNFINISHED,
                    AccessUserTypes.UNVERIFIED,
                  ],
                }}
              >
                <MembershipHubPage />
              </WithAuthAndAccessCheck>
            ),
          },
          {
            path: routes.login,
            element: <LoginPage />,
          },
          {
            path: routes.membershipPlanOverview,
            element: (
              <WithAuthAndAccessCheck
                userAccessRights={{
                  allowedUsers: [
                    AccessUserTypes.ACTIVE_B2C_ALL, // gen2 up-graders belong here after pw reset
                    AccessUserTypes.INACTIVE_B2C_ALL,
                    AccessUserTypes.ACTIVE_B2B,
                    AccessUserTypes.INACTIVE_B2B,
                    AccessUserTypes.LIFETIME,
                  ],
                }}
              >
                <MembershipPlanPage />
              </WithAuthAndAccessCheck>
            ),
          },
          {
            path: routes.SelectAddressUsage, // only B2C users with valid address can access
            element: (
              <WithAuthAndAccessCheck
                userAccessRights={{
                  allowedUsers: [
                    AccessUserTypes.ACTIVE_B2C_ADDRESS_PAYMENT_METHOD,
                    AccessUserTypes.ACTIVE_B2C_ADDRESS_NO_PAYMENT_METHOD,
                    AccessUserTypes.INACTIVE_B2C_ADDRESS_PAYMENT_METHOD,
                    AccessUserTypes.INACTIVE_B2C_ADDRESS_NO_PAYMENT_METHOD,
                  ],
                }}
              >
                <PaymentDetailsPageSelectAddressUsage />
              </WithAuthAndAccessCheck>
            ),
          },
          {
            path: routes.reviewPaymentMethodForRenew,
            element: (
              <WithAuthAndAccessCheck
                userAccessRights={{
                  allowedUsers: [
                    AccessUserTypes.INACTIVE_B2C_ALL,
                    AccessUserTypes.INACTIVE_B2B,
                  ],
                }}
              >
                <ReviewPaymentMethodForRenew />
              </WithAuthAndAccessCheck>
            ),
          },
          {
            path: routes.addressForm,
            element: (
              <WithAuthAndAccessCheck
                userAccessRights={{
                  allowedUsers: [
                    AccessUserTypes.ACTIVE_B2C_ALL,
                    AccessUserTypes.INACTIVE_B2C_ALL,
                    AccessUserTypes.INACTIVE_B2B,
                  ],
                }}
              >
                <AddressPage />
              </WithAuthAndAccessCheck>
            ),
          },
          {
            path: routes.selectPlan, // renew - update plan (expired)
            element: (
              <WithAuthAndAccessCheck
                userAccessRights={{
                  allowedUsers: [
                    AccessUserTypes.INACTIVE_B2C_ALL,
                    AccessUserTypes.INACTIVE_B2B,
                  ],
                }}
              >
                <RenewSelectPlanPage />
              </WithAuthAndAccessCheck>
            ),
          },
          {
            path: routes.updatePaymentMethod,
            element: (
              <WithAuthAndAccessCheck
                userAccessRights={{
                  allowedUsers: [
                    AccessUserTypes.ACTIVE_B2C_ALL,
                    AccessUserTypes.INACTIVE_B2C_ALL,
                    AccessUserTypes.INACTIVE_B2B,
                  ],
                }}
              >
                <PaymentMethodUpdatePage />
              </WithAuthAndAccessCheck>
            ),
          },
          {
            path: routes.updateAccountInfo,
            element: (
              <WithAuthAndAccessCheck
                userAccessRights={{
                  excludedUsers: [
                    AccessUserTypes.UNFINISHED,
                    AccessUserTypes.UNVERIFIED,
                  ],
                }}
              >
                <AccountManagePage />
              </WithAuthAndAccessCheck>
            ),
          },
          {
            path: routes.requestPasswordReset,
            element: <RequestPasswordResetPage />,
          },
          {
            path: routes.forgotPasswordResetSetPassword,
            element: <ResetPasswordPage />,
          },
          {
            path: routes.billingHistory, // must be B2C with Payment_Method
            element: (
              <WithAuthAndAccessCheck
                userAccessRights={{
                  allowedUsers: [
                    AccessUserTypes.ACTIVE_B2C_ADDRESS_PAYMENT_METHOD,
                    AccessUserTypes.ACTIVE_B2C_NO_ADDRESS_PAYMENT_METHOD,
                    AccessUserTypes.INVALID_CURRENCY_ACTIVE_B2C,
                    AccessUserTypes.INACTIVE_B2C_ADDRESS_PAYMENT_METHOD,
                    AccessUserTypes.INACTIVE_B2C_NO_ADDRESS,
                  ],
                }}
              >
                <ViewBillingHistoryPage />
              </WithAuthAndAccessCheck>
            ),
          },
          {
            path: routes.verifyNewEmail,
            element: <VerifyEmailPage />,
          },
          {
            path: routes.updatePaymentMethodSuccess, // payment update
            element: (
              <WithAuthAndAccessCheck
                userAccessRights={{
                  allowedUsers: [AccessUserTypes.ACTIVE_B2C_ALL],
                }}
              >
                <UpdatePaymentMethodSuccess renew={false} />
              </WithAuthAndAccessCheck>
            ),
          },
          {
            path: routes.updatePaymentMethodSuccessRenew, // renew for expired & pending cancellation
            element: (
              <WithAuthAndAccessCheck
                userAccessRights={{
                  allowedUsers: [
                    AccessUserTypes.ACTIVE_B2C_ALL,
                    AccessUserTypes.INACTIVE_B2C_ALL,
                    AccessUserTypes.INACTIVE_B2B,
                  ],
                }}
              >
                <UpdatePaymentMethodSuccess renew={true} />
              </WithAuthAndAccessCheck>
            ),
          },
          {
            path: routes.updatePlan, // only done via membership page, must have valid pm
            element: (
              <WithAuthAndAccessCheck
                userAccessRights={{
                  allowedUsers: [
                    AccessUserTypes.ACTIVE_B2C_ADDRESS_PAYMENT_METHOD,
                    AccessUserTypes.ACTIVE_B2C_NO_ADDRESS_PAYMENT_METHOD,
                  ],
                }}
              >
                <UpdatePlanPage />
              </WithAuthAndAccessCheck>
            ),
          },
          {
            path: routes.memberExperience,
            element: (
              <WithAuthAndAccessCheck
                userAccessRights={{
                  excludedUsers: [
                    AccessUserTypes.LEGACY,
                    AccessUserTypes.UNFINISHED,
                    AccessUserTypes.UNVERIFIED,
                  ],
                }}
              >
                <MembershipExperiencePage />
              </WithAuthAndAccessCheck>
            ),
          },
          {
            path: routes.paypalCallback,
            element: (
              <PaypalCallbackPage
                onSuccess={onCallbackSuccess(PaymentMethodType.PayPal)}
                onError={onCallbackError(PaymentMethodType.PayPal)}
                isUnitedStates={
                  useStore.getState().userAddressForm?.shippingAddress
                    .country === "US"
                }
                paymentGateway={useStore.getState().gateways.paypalGatewayName}
              />
            ),
          },
          {
            path: routes.hsaCallback,
            element: (
              <HSACallbackPage
                onSuccess={onCallbackSuccess(PaymentMethodType.HSA_FSA)}
                onError={onCallbackError(PaymentMethodType.HSA_FSA)}
              />
            ),
          },
          {
            path: routes.error,
            element: <ErrorPage errorPageType={"error"} appType="hub" />,
          },
          {
            path: "*",
            element: <ErrorPage errorPageType={"notFound"} appType="hub" />,
          },
        ],
      },
    ],
  },
  {
    path: routes.paymentMethodCallback, // Don't add as a children of the main route, as it's rendered inside an iframe
    element: (
      <CreditCardCallbackPage
        onSuccess={onCallbackSuccess(PaymentMethodType.CreditCard)}
        onError={onCallbackError(PaymentMethodType.CreditCard)}
      />
    ),
  },
]);

export const Router = () => <RouterProvider router={router} />;
